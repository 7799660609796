import { z } from "zod";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const localePath = useLocalePath();

  const userStore = useUserStore();

  const accessedEventId = to.params.id;

  if (typeof accessedEventId !== "string") {
    return abortNavigation();
  }

  const eventIdSchema = z.string().uuid();
  const verifiedEventId = eventIdSchema.safeParse(accessedEventId);

  if (!verifiedEventId.success) {
    return abortNavigation();
  }

  const fetchEventResult = await useFetch(
    `/api/events/${verifiedEventId.data}`
  );
  const maybeError = fetchEventResult.error.value;
  if (maybeError !== null) {
    if (maybeError.statusCode === 404) {
    }
    return await navigateTo(localePath("/"));
  }

  const eventId = verifiedEventId.data;

  // Si l'utilisateur n'est pas connecté, redirige vers la page de connexion uniquement si nécessaire

  if (userStore.user === null) {
    if (to.path !== localePath(`/events/${eventId}/rejoindre`)) {
      return navigateTo(localePath(`/events/${eventId}/rejoindre`));
    }
    return;
  }

  const { data, error } = await useFetch(
    `/api/events/${eventId}/participants`,
    {
      query: {
        userId: userStore.user.id,
      },
    }
  );

  // Si l'utilisateur n'est pas dans les participants, redirige vers la page rejoindre
  if (error.value !== null || !data.value || data.value?.length === 0) {
    if (to.path !== localePath(`/events/${eventId}/rejoindre`)) {
      return navigateTo(localePath(`/events/${eventId}/rejoindre`));
    }
    return;
  }
});
